import React from "react";

interface DropdownButtonProps {
  text: string;
  onClick: () => void;
  extra?: string;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  text,
  onClick,
  extra,
}) => {
  return (
    <button
      type="button"
      className={`
        flex h-full w-full items-center rounded-md bg-white p-1 text-left text-primary 
        transition duration-200 hover:bg-gray-200 active:bg-gray-300 
        disabled:bg-gray-200 dark:bg-white/10 dark:text-white 
        dark:hover:bg-white/20 dark:active:bg-white/30 ${extra}
      `}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default DropdownButton;
