import { deviceSet } from "api/device";
import { Device, DeviceSetBody } from "api/types/device";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { getReadings } from "util/device";
import DeviceCard from "widgets/cards/device";
import DeviceGeneralForm, {
  DeviceGeneralFormType,
} from "widgets/forms/devices/general";
import QrCodeButton from "widgets/shared/buttons/qrcode";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  onUpdate?: () => void;
}

const General: React.FC<GeneralProps> = ({ device, config, onUpdate }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const doDeviceSet = async (formData: DeviceGeneralFormType) => {
    const body: DeviceSetBody = {
      snr: device?.snr,
      meterNumber: formData.meterNumber,
      meterModel: formData.meterModel,
      address: formData.address,
      unitNumber: formData.unitNumber,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await deviceSet(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <DeviceCard
      showIcon
      title={device?.name}
      subTitle={`${t("generic.serial")}: ${device.snr || ""}`}
      subTitle1={`${t("meter.reading")}: ${getReadings(device)}`}
      config={config}
      topRight={
        <>
          <MdEditDocument
            className="heading-lg cursor-pointer"
            onClick={() => {
              setEdit(!edit);
            }}
          />
          <QrCodeButton data={device?.snr} class={`text-secondary`} />
        </>
      }
    >
      <DeviceGeneralForm
        values={{
          meterNumber: device?.meterNumber || "",
          meterModel: device?.meterModel || "",
          address: device?.address || "",
          unitNumber: device?.unitNumber || "",
        }}
        submit={doDeviceSet}
        onClose={() => {}}
        disabled={!edit}
      />
    </DeviceCard>
  );
};

export default General;
