import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import {
  MdClear,
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
} from "react-icons/md";
import DropdownButton from "../buttons/dropdown";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { useDisclosure } from "@chakra-ui/hooks";
import { SearchInput } from "./search";
import { t } from "i18next";
import { Portal, PortalManager } from "@chakra-ui/portal";
import GenericButton from "../buttons/generic";

type ValueSet = {
  [key: string]: any;
};

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: any;
  onChange: (values: any) => void;
  debounce?: number;
  items: ValueSet[];
  placeholder: string;
  labelKey?: string;
  valueKey?: string;
  filter?: boolean;
  selectAll?: boolean;
  state?: "error" | "success"; // Specific states for styling
  onFocus?: () => void;
  placement?: "top" | "bottom";
  clearIcon?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  minWidth?: string; // Add minWidth prop
  variant?: "solid" | "outline" | "filter";
  color?: "primary" | "secondary";
  rounded?: string;
  loading?: boolean;
}

const MultiCombobox = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      id,
      debounce = 500,
      labelKey = "label",
      valueKey = "value",
      variant = "outline",
      placement = "bottom",
      sizes = "lg",
      selectAll = false,
      clearIcon = true,
      filter = false,
      items,
      placeholder,
      disabled,
      readOnly,
      onChange,
      onFocus,
      onBlur,
      state,
      minWidth = "200px",
      color = "primary",
      rounded = "rounded-2xl",
      loading = false,
    },
    ref
  ) => {
    //       variant = "input",

    const { onOpen, onClose, isOpen } = useDisclosure();
    const [selectedValues, setSelectedValues] = useState<any[]>(value);

    const [filterTerm, setFilterTerm] = useState("");

    const filteredItems = items.filter((item) =>
      item[labelKey].toLowerCase().includes(filterTerm.toLowerCase())
    );

    useEffect(() => {
      if (value && value.length > 0) {
        setSelectedValues(value);
      }
    }, []);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(selectedValues);
        setFilterTerm("");
      }, debounce);

      return () => clearTimeout(timeout);
    }, [selectedValues]);

    const handleSelectAll = () => {
      const allValues = items.map((item) => item[valueKey]);
      setSelectedValues(allValues);
      onChange(allValues);
      onClose();
    };

    const handleDeselectAll = () => {
      setSelectedValues([]);
      onChange([]);
      onClose();
    };

    const toggleSelectItem = (itemValue: any) => {
      let newValues;

      // Check if selectedValues already has entries
      if (selectedValues && selectedValues.length > 0) {
        // If item is already selected, remove it; otherwise, add it
        newValues = selectedValues.includes(itemValue)
          ? selectedValues.filter((val) => val !== itemValue)
          : [...selectedValues, itemValue];
      } else {
        // For the first entry, add the item directly
        newValues = [itemValue];
      }

      setSelectedValues(newValues);
    };

    const isDisabled = disabled || loading || readOnly;

    const colorClasses = {
      primary: {
        filter: `text-primary bg-gray-50`,
        solid: `text-primary bg-gray-50/10`,
        outline: `border border-brand-700 text-primary bg-transparent`,
      },
      secondary: {
        filter: `text-secondary bg-gray-50`,
        solid: `text-secondary bg-gray-50/10`,
        outline: `border border-secondary text-secondary bg-transparent`,
      },
    };

    const sizeClasses = {
      lg: "h-12 px-6 text-txt-lg",
      md: "h-10 px-4 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const popSizeClasses = {
      lg: "h-8 px-3 text-txt-lg",
      md: "h-8 px-3 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const stateClasses = {
      error: "outline-red-500 text-red-500 placeholder:text-red-500",
      success: "outline-green-500 text-green-500 placeholder:text-green-500",
    };

    return (
      <div className={`flex ${minWidth}`}>
        <Popover
          isOpen={isOpen && !disabled}
          onOpen={() => {
            onOpen();
          }}
          onClose={onClose}
          closeOnBlur={true}
          matchWidth
          placement={placement}
        >
          <PopoverTrigger>
            <button
              type="button"
              id={id}
              onFocus={onFocus}
              disabled={disabled || readOnly}
              className={`flex w-full items-center justify-center  ${rounded} ${
                sizeClasses[sizes]
              } ${colorClasses[color][variant]} ${
                state ? stateClasses[state] : "outline-none"
              } ${
                isDisabled
                  ? "border-none bg-gray-50/50"
                  : "hover:opacity-50 focus:opacity-100"
              }`}
            >
              <div className="flex w-full items-center justify-between gap-2 sm:gap-4">
                <div className="flex items-center gap-2 truncate">
                  {selectedValues && selectedValues.length > 0 ? (
                    <span className="truncate">
                      {selectedValues
                        .map(
                          (val) =>
                            filteredItems.find(
                              (item) => item[valueKey] === val
                            )?.[labelKey]
                        )
                        .join(", ")}
                    </span>
                  ) : (
                    <span className="truncate text-gray-500">
                      {placeholder}
                    </span>
                  )}
                </div>
                <div className=" flex items-center gap-2">
                  {selectedValues && selectedValues.length > 0 && clearIcon && (
                    <MdClear
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeselectAll();
                      }}
                      className="cursor-pointer"
                    />
                  )}
                  {isOpen ? (
                    <MdOutlineArrowCircleUp className="" />
                  ) : (
                    <MdOutlineArrowCircleDown className="" />
                  )}
                </div>
              </div>
            </button>
          </PopoverTrigger>
          {items && items.length > 0 && (
            <Portal>
              <PopoverContent
                zIndex={5500}
                bg="white"
                className="`w-max max-h-60 rounded-xl border px-1 py-2"
              >
                <>
                  {filter && (
                    <div className="px-2 pb-2">
                      <SearchInput
                        onChange={(filters) => {
                          setFilterTerm(filters);
                        }}
                        type="text"
                        placeholder={t("generic.action.search") + "..."}
                        value={filterTerm}
                        sizes={sizes}
                      />
                    </div>
                  )}
                  {selectAll && (
                    <div className="flex flex-wrap items-center justify-between">
                      <GenericButton
                        text="Select All"
                        onClick={handleSelectAll}
                        size={sizes}
                        variant="link"
                      />
                      <GenericButton
                        text="Deselect All"
                        onClick={handleDeselectAll}
                        size={sizes}
                        variant="link"
                      />
                    </div>
                  )}

                  <div className="max-h-60 overflow-y-scroll">
                    {filteredItems.map((item: any, index: any) => (
                      <DropdownButton
                        key={index}
                        text={item[labelKey]}
                        onClick={() => toggleSelectItem(item[valueKey])}
                        extra={popSizeClasses[sizes]}
                      />
                    ))}
                  </div>
                </>
              </PopoverContent>
            </Portal>
          )}
        </Popover>
      </div>
    );
  }
);

export default MultiCombobox;
