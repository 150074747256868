import { useAuth } from "api/hooks/useAuth";
import { useState } from "react";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";
import MfaDialog from "widgets/dialogs/mfa";
import { t } from "i18next";
import { notifySimple } from "providers/toast";

const SetUp = (props: {
  value: string;
  name: string;
  py?: string;
  actionName: "Set Up" | "Disable";
  border?: string;
}) => {
  const { value, name, py, actionName, border } = props;
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [secretCode, setSecretCode] = useState("");

  // Get MFA secretkey
  const doSetupMFA = async () => {
    notifySimple("Comming soon!", "info");
    // if (actionName === "Set Up") {
    //   try {
    //     const response = await auth.setupTOTP();
    //     if (response.success) {
    //       setQrCode(response.qrCode);
    //       setSecretCode(response.secretCode);
    //       toggleDialog();
    //     }
    //   } catch (error) {}
    // } else if (actionName === "Disable") {
    //   toggleDialog();
    // }
  };

  // Open/Close dialog
  const toggleDialog = () => {
    if (actionName === "Set Up") {
      setOpen(!open);
    } else if (actionName === "Disable") {
      setOpen1(!open1);
    }
  };

  // Verify MFA
  const doVerifyMFA = (code: string) => {
    if (code && code.length === 6) {
      toggleDialog();
      auth.verifyTotpToken(code);
    }
  };

  // Disable MFA
  const doDisableMFA = () => {
    toggleDialog();
    auth.disableTotp();
  };

  return (
    <>
      <div className={`flex flex-col items-end justify-between md:flex-row`}>
        <div className="flex flex-col items-center py-4 md:items-start md:py-2 lg:py-0">
          <p className="heading-lg mb-1 text-primary dark:text-darkPrimary">
            {" "}
            {name}{" "}
          </p>
          <p className="text-txt-md text-tertiary">{value}</p>
        </div>

        <div className="flex flex-col items-center justify-center md:flex-row">
          <GenericButton
            variant="outline"
            size="md"
            onClick={doSetupMFA}
            text={actionName}
            extra="bg-transparent"
          />
        </div>

        <GenericDialog
          isOpen={open1}
          title={t("dialog.warning.title")}
          onClose={toggleDialog}
          onOk={doDisableMFA}
        >
          <div>{t("profile.setup.warning")}</div>
        </GenericDialog>
      </div>
      <MfaDialog
        isOpen={open}
        code={qrCode}
        secret={secretCode}
        onClose={toggleDialog}
        onOk={(val) => {
          doVerifyMFA(val);
        }}
      />
    </>
  );
};

export default SetUp;
