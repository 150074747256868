// import authImg from "assets/img/auth/atomAuth.png";
// import authLogo from "assets/img/auth/authLogo.png";
// import Footer from "components/footer/FooterAuthDefault";
// import { t } from "i18next";
// function Default(props: { maincard: JSX.Element }) {
//   const { maincard } = props;
//   return (
//     <div className="relative min-h-screen">
//       <div className="grid min-h-screen grid-cols-1 lg:grid-cols-2">
//         <div className="col-span-1 flex flex-col justify-center px-6 py-0 pt-0 md:px-28 lg:px-24 2xl:px-40 3xl:px-52">
//           {maincard}
//         </div>
//         <div className="hidden lg:block">
//           <div className="relative flex h-full w-full">
//             <div
//               style={{ backgroundImage: `url(${authImg})` }}
//               className="relative h-screen w-full bg-cover bg-center"
//             />
//             <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center pt-56">
//               <img src={authLogo} alt="Overlay" className="w-56" />
//               <div className="mt-10 flex flex-col items-center">
//                 <p className="fixed-plugin mb-2">
//                   {t("fixed.plugin.engineered")}
//                 </p>
//                 <p className="fixed-plugin mb-2">
//                   {t("fixed.plugin.sustainability")}
//                 </p>
//                 <p className="fixed-plugin">{t("fixed.plugin.solutions")}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="z-10 mt-4 w-full md:absolute md:bottom-0">
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default Default;

import authImg from "assets/img/auth/atomAuth.png";
import authLogo from "assets/img/auth/authLogo.png";
import Footer from "components/footer/FooterAuthDefault";
import { t } from "i18next";

function Default(props: { maincard: JSX.Element }) {
  const { maincard } = props;
  return (
    <div className="relative min-h-screen">
      <div className="grid min-h-screen grid-cols-1 lg:grid-cols-2">
        <div className="mx-auto block h-40 pb-4 pt-6 lg:hidden">
          <img src={authLogo} alt="Overlay" className="w-28" />
        </div>
        <div className="col-span-1 flex flex-col px-6 py-0 pt-0 md:px-28 lg:justify-center lg:px-24 2xl:px-40 3xl:px-52">
          {maincard}
        </div>
        <div className="hidden lg:block">
          {/* Background image and logo for larger screens */}
          <div className="relative flex h-full w-full">
            <div
              style={{ backgroundImage: `url(${authImg})` }}
              className="relative h-screen w-full bg-cover bg-center"
            />
            <div className="absolute left-0 top-0 z-0 flex h-full w-full flex-col items-center pt-56">
              <img src={authLogo} alt="Overlay" className="w-56" />
              <div className="mt-10 flex flex-col items-center">
                <p className="fixed-plugin mb-2">
                  {t("fixed.plugin.engineered")}
                </p>
                <p className="fixed-plugin mb-2">
                  {t("fixed.plugin.sustainability")}
                </p>
                <p className="fixed-plugin">{t("fixed.plugin.solutions")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="z-10 mt-4 w-full md:absolute md:bottom-0">
        <Footer />
      </div>
    </div>
  );
}

export default Default;
