import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import Checkbox from "widgets/shared/inputs/checkbox";
import PhoneNumber from "widgets/shared/inputs/phoneNumber";
import { PhoneNumberUtil } from "google-libphonenumber";
import GenericButton from "widgets/shared/buttons/generic";

const phoneUtil = PhoneNumberUtil.getInstance();

// Schema
const SignUpSchema = z.object({
  name: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  family_name: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  email: z
    .string()
    .min(1, { message: i18next.t("zod.error.required") })
    .email({ message: i18next.t("zod.error.email") }),
  phone_number: z
    .string()
    .min(5, {
      message: i18next.t("zod.error.phone.number"),
    })
    .refine(
      (phone_number) => {
        try {
          return phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone_number)
          );
        } catch (error) {
          return false;
        }
      },
      {
        message: i18next.t("zod.error.phone.valid"),
      }
    ),
  password: z
    .string()
    .min(8, {
      message: i18next.t("zod.error.password.length"),
    })
    .refine(
      (password) => {
        // Check if newPassword contains at least one number and one special character
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialCharacter =
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
        return hasNumber && hasSpecialCharacter;
      },
      {
        message: i18next.t("zod.error.password.match"),
      }
    ),
  consent: z
    .boolean({
      message: i18next.t("zod.error.consent"),
    })
    .refine((val) => val === true, {
      message: i18next.t("zod.error.consent"),
    }),
});

export type SignUpFormType = z.infer<typeof SignUpSchema>;

// Props
type SignUpFormProps = {
  submit: (data: SignUpFormType) => void;
};

const SignUpForm = ({ submit }: SignUpFormProps) => {
  const form = useForm<SignUpFormType>({
    resolver: zodResolver(SignUpSchema),
    defaultValues: {
      name: "",
      family_name: "",
      email: "",
      phone_number: "",
      password: "",
      consent: false,
    },
  });

  const { t } = useTranslation();
  const { errors } = form.formState;

  const onSubmit = async (formData: SignUpFormType) => {
    try {
      await form.trigger();
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-0">
          <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-5">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.name")}</FormLabel>
                  <FormControl>
                    <InputField
                      placeholder={t("form.placeholder.name")}
                      id="name"
                      type="text"
                      state={errors.name ? "error" : undefined}
                      onFocus={clearErrors}
                      extra="bg-transparent"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="family_name"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.family.name")}</FormLabel>
                  <FormControl>
                    <InputField
                      placeholder={t("form.placeholder.family.name")}
                      id="family_name"
                      type="text"
                      state={errors.family_name ? "error" : undefined}
                      onFocus={clearErrors}
                      extra="bg-transparent"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.email")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.email")}
                    id="email"
                    type="text"
                    state={errors.email ? "error" : undefined}
                    onFocus={clearErrors}
                    extra="bg-transparent"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone_number"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.phone.number")}</FormLabel>
                <FormControl>
                  <PhoneNumber
                    id="phone_number"
                    placeholder={t("form.placeholder.phone.number")}
                    state={errors.phone_number ? "error" : undefined}
                    onFocus={clearErrors}
                    value={field.value}
                    onChang={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.password")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.password")}
                    id="password"
                    type="password"
                    state={errors.password ? "error" : undefined}
                    onFocus={clearErrors}
                    extra="bg-transparent"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="consent"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>&nbsp;</FormLabel>
                <FormControl>
                  <Checkbox
                    id="checkbox-single"
                    color="blue"
                    labelKey={t("form.input.consent")}
                    checked={field.value}
                    valueKey={true}
                    onChange={() => field.onChange(!field.value)}
                    state={errors.consent ? "error" : undefined}
                    onFocus={clearErrors}
                    sizes="md"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <GenericButton
          type="submit"
          text={t("auth.sign_up.action")}
          extra="w-full"
        />
      </form>
    </Form>
  );
};

export default SignUpForm;
