import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { t } from "i18next";
import LabeledField from "providers/labeledField";
import { formatDate } from "util/datelib";

const formatValue = (key: any, value: string | number | Date) => {
  switch (key) {
    case "created":
    case "lastcomms":
      return formatDate(new Date(value), "lastcomms"); // Format the date and convert it to a string

    default:
      return String(value);
  }
};

const GlobalSearchDialog = ({
  isOpen,
  onClose,
  result,
}: {
  isOpen: boolean;
  onClose: () => void;
  result: Record<string, string>;
}) => {
  const keys = Object.keys(result);

  return (
    <>
      <PortalManager zIndex={2000}>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay className="!bg-black !fixed !inset-0 !opacity-30" />
          <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[40%] rounded-xl border bg-white p-5 md:top-[12vh]">
            <ModalHeader className="heading-md mb-4 text-primary">
              <div className="flex justify-between">
                {t("global.result.title")}
                <ModalCloseButton className="text-txt-sm text-primary" />
              </div>
            </ModalHeader>

            <ModalBody className="flex flex-col gap-2">
              <div className="grid grid-cols-1 gap-1 md:gap-2">
                {keys.map((item: string, index) => (
                  <LabeledField
                    label={`${t("global.result.item", { context: item })}: `}
                    id={`result-${item}`}
                    key={index}
                    className="heading-sm"
                  >
                    <label
                      className={`whitespace-normal break-words text-txt-md text-tertiary`}
                    >
                      {formatValue(item, result[item])}
                    </label>
                  </LabeledField>
                ))}
              </div>
            </ModalBody>
            <ModalFooter className="mt-4 gap-2"></ModalFooter>
          </ModalContent>
        </Modal>
      </PortalManager>
    </>
  );
};

export default GlobalSearchDialog;
