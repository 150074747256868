import { t } from "i18next";
import { shallowEqual, useSelector } from "react-redux";
import { userFullname } from "store/user/userSelectors";
import DashboardCard from "widgets/cards/dashboard";
import profile from "assets/img/crm/vbz.png";
import CircleButton from "widgets/shared/buttons/circle-button";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MenuRoutePaths } from "models/constants/routes";

const Profile = () => {
  const fullname = useSelector(userFullname, shallowEqual);
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate(`/admin${MenuRoutePaths.PROFILE}`);
  };
  return (
    <DashboardCard
      title={t("dashboard.card.profile.title")}
      bgColor="bg-brand-50"
      textColor="text-secondary"
      extra="h-full"
    >
      <div className="flex-grow"></div>
      <div className="flex flex-col gap-2">
        <img
          className="h-[80px] w-[80px] rounded-full border-4 border-white"
          src={profile}
          alt=""
        />
        <div className="heading-lg text-secondary">{fullname}</div>
      </div>
      <div className="absolute bottom-4 right-2">
        <CircleButton
          label={t("dashboard.card.action", { context: "profile" })}
          size="xs"
          variant="ghost"
          color="secondary"
          icon={GoArrowRight}
          iconPosition="right"
          onClick={handleRoute}
        />
      </div>
    </DashboardCard>
  );
};

export default Profile;
