import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  PaginationOptions,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import BounceLoader from "react-spinners/ClipLoader"; // Import the loader

import { t } from "i18next";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";

type Props<T extends Record<string, string | number>> = {
  data: any;
  columns: any;
  pagination: PaginationState;
  paginationOptions: Pick<
    PaginationOptions,
    "onPaginationChange" | "rowCount" | "pageCount"
  >;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  onRowClick?: (row: any) => void;
  loading?: boolean; // Add loading prop
  filterbar?: React.ReactNode;
};

export default function Table<T extends Record<string, string | number>>({
  data,
  columns,
  pagination,
  paginationOptions,
  sorting,
  onSortingChange,
  onRowClick,
  loading, // Destructure the loading prop
  filterbar,
}: Props<T>) {
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!loading && data.length === 0) {
      timer = setTimeout(() => setShowNoData(true), 2000); // 2-second delay
    } else {
      setShowNoData(false); // Reset if data is loading or available
    }

    return () => {
      if (timer) clearTimeout(timer); // Clear timeout on cleanup
    };
  }, [loading, data]);

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    onSortingChange,
    ...paginationOptions,
    manualFiltering: true,
    manualSorting: true,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={"flex h-full w-full flex-col"}>
      {filterbar && <div className="py-[20px]">{filterbar}</div>}

      <div className="flex h-full flex-col overflow-x-scroll pb-4 xl:overflow-x-auto">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="">
                {headerGroup.headers.map((header) => {
                  const customHeadClass = (header.column.columnDef.meta as any)
                    ?.customHeadClass;
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className={`heading-xs h-10 cursor-pointer bg-brand-50 p-3 text-white ${customHeadClass}`}
                      style={{
                        ...(header.column.columnDef.meta as any)?.style,
                      }}
                    >
                      <div className="flex items-center gap-6 text-nowrap text-white">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() === "asc" ? (
                          <span className="flex h-4 w-4 items-center justify-center rounded-full bg-white p-2 text-primary">
                            ↑
                          </span>
                        ) : header.column.getIsSorted() === "desc" ? (
                          <span className="flex h-4 w-4 items-center justify-center rounded-full bg-white p-2 text-primary">
                            ↓
                          </span>
                        ) : header.column.getCanSort() ? (
                          <span className="flex h-4 w-4 items-center justify-center rounded-full bg-white p-2 text-primary">
                            ⇅
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {/* Scrollable Table Body */}

          <tbody className="overflow-y-scroll">
            {loading ? (
              <tr>
                <td colSpan={columns.length} className="p-4 text-center">
                  <BounceLoader color="#36D7B7" />
                </td>
              </tr>
            ) : showNoData ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center text-txt-md text-primary"
                >
                  No data to display
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-b border-gray-200 dark:border-white/30"
                >
                  {row.getVisibleCells().map((cell) => {
                    const isClickable = (cell.column.columnDef.meta as any)
                      ?.rowClick;
                    const customClass = (cell.column.columnDef.meta as any)
                      ?.customClass;

                    return (
                      <td
                        key={cell.id}
                        className={`h-10 min-w-[50px] max-w-[500px] text-nowrap p-2 text-txt-sm text-primary dark:text-darkPrimary ${
                          isClickable ? "cursor-pointer" : ""
                        } ${customClass}`}
                        onClick={(e) => {
                          if (isClickable) {
                            onRowClick && onRowClick(row.original);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="grow"></div>

      {/* left side */}
      {/* <div className="mb-2 flex h-20 items-center py-4">
        <p className="text-sm text-secondary dark:text-darkSecondary">
          {paginationOptions.rowCount > 0
            ? t("table.status.rowsPerPage", {
                offset: table.getState().pagination.pageIndex + 1,
                end: table.getState().rowSelection,
                total: paginationOptions.rowCount,
              })
            : t("table.status.noData")}
        </p>
      </div> */}

      {/* Pagination */}

      {paginationOptions.pageCount > 1 && (
        <div className="mb-2 flex h-20 items-center justify-end py-4">
          <div
            className="flex items-center gap-2"
            style={{
              visibility:
                paginationOptions.pageCount > 1 ? "visible" : "hidden",
            }}
          >
            <ResponsivePagination
              current={table.getState().pagination.pageIndex + 1}
              total={table.getPageCount()}
              onPageChange={(pageNumber) => {
                table.setPageIndex(pageNumber - 1);
              }}
              className="flex h-12 items-center text-sm font-bold text-tertiary "
              pageItemClassName="p-4 mx-1 h-4 w-4 items-center justify-center flex text-txt-sm border border-brand-700"
              activeItemClassName="bg-primary text-white "
              navClassName="border-0"
              previousLabel={
                <>
                  <MdChevronLeft className="h-6 w-6 text-primary dark:text-darkPrimary" />
                </>
              }
              nextLabel={
                <>
                  <MdChevronRight className="h-6 w-6 text-primary dark:text-darkPrimary" />
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
