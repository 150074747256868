import {
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryEmpty,
  FaCheckCircle,
  FaTimesCircle,
  FaExclamationCircle,
  FaQuestionCircle,
  FaCircle,
} from "react-icons/fa";
import {
  MdSignalCellular4Bar,
  MdSignalCellular1Bar,
  MdSignalCellularOff,
} from "react-icons/md";
import Badge from "widgets/shared/badge";

export const deviceStatusIcon = (
  battery: number | null,
  signal: number | null
) => {
  const batteryIcon =
    battery === null ? null : battery > 3 ? (
      <Badge label={`${battery} V`} color="green" />
    ) : battery > 1 ? (
      <Badge label={`${battery} V`} color="yellow" />
    ) : (
      <Badge label={`${battery} V`} color="red" />
    );

  const signalIcon =
    signal === null ? null : signal >= -125 ? (
      <Badge label={`${"GOOD"}`} color="green" />
    ) : signal < -125 && signal >= -135 ? (
      <Badge label={`${"AVERAGE"}`} color="yellow" />
    ) : (
      <Badge label={`${"WEAK"}`} color="red" />
    );

  return { batteryIcon, signalIcon };
};

export const deviceVerifiedIcon = (dataQuality: string | null) => {
  const verifiedIcon =
    dataQuality === null ? null : dataQuality === "VERIFIED" ? (
      <FaCheckCircle className="text-green-500" />
    ) : dataQuality === "UNVERIFIED" ? (
      <FaTimesCircle className="text-yellow-500" />
    ) : dataQuality === "ERROR" ? (
      <FaExclamationCircle className="text-red-500" />
    ) : dataQuality === "QUERIED" ? (
      <FaQuestionCircle className="text-blue-500" />
    ) : (
      <FaCircle className="text-gray-500" />
    );

  return { verifiedIcon };
};
