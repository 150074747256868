import { ViewPort } from "models/constants/styling";
import Devices from "./overview/components/Devices";
import MapCard from "./overview/components/MapCard";
import Profile from "./overview/components/Profile";
import Reports from "./overview/components/Reports";
import Sites from "./overview/components/Sites";
import Users from "./overview/components/Users";
import { getDashboard } from "api/dashboard";
import { getDashboardDeviceCount, useAppDispatch } from "util/hooks";
import { setLoading } from "store/appSlice";
import { DashboardReqBody, Section } from "api/types/dashboard";
import { useEffect, useState } from "react";
import { ModelFilterDropdown } from "models/dropdowns/modelFilter";

const SmartHome = () => {
  const dispatch = useAppDispatch();
  const [metrics, setMetrics] = useState<any | null>(null);

  const getDashboardData = async () => {
    const sections: DashboardReqBody = {
      sections: [
        Section.USER_COUNT,
        Section.SITE_COUNT,
        Section.DEVICE_COUNT,
        Section.DEVICE_LOCATIONS,
        Section.DEVICE_MODEL_BREAKDOWN,
      ],
    };
    dispatch(setLoading(true));
    const { data, isLoading, status } = await getDashboard(sections);
    dispatch(setLoading(isLoading));
    if (status === 200) {
      const counts = getDashboardDeviceCount(
        ModelFilterDropdown,
        data.deviceModelBreakdown
      );

      setMetrics((prev: any) => ({
        ...prev,
        data,
        counts,
      }));
    } else {
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className={`mt-0 sm:mt-6 md:h-contentFitHeight`}>
      <div className={`grid h-full w-full grid-cols-1 gap-2 `}>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 xl:max-h-52">
          <Profile />
          <Users count={metrics?.data.userCount} />
          <Sites count={metrics?.data.siteCount} />
        </div>
        <div className="grid grid-cols-1 gap-2  sm:grid-cols-1 lg:grid-cols-4">
          <Devices type="water" count={metrics?.counts.water} />
          <Devices type="electricity" count={metrics?.counts.electricity} />
          <Devices type="temperature" count={metrics?.counts.temperature} />
          <Devices type="level" count={metrics?.counts.level} />
          {/* <Devices type="qr_code" count={metrics?.counts.qr_code} /> */}
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-2">
            <MapCard markers={metrics?.data.deviceLocations} />
          </div>
          <div className="col-span-1">
            <Reports />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartHome;
